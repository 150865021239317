<template>
  <div class="charts-content">
    <div ref="volumeOfTrade" class="volume-trade" />
  </div>
</template>

<script>
// 交易额同比环比
import { orderSummaryGetPlatTradeAmount } from '@/api/monitor'
export default {
  props: { params: {
    default: () => { return {} },
    type: Object
  }},
  data() {
    return {
      tradeAmountObj: {},
      myChart: null
    }
  },
  watch: {
    params: {
      handler(newval) {
        if ([0].includes(newval.type)) this.getData(newval)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(val) {
      this.myChart = this.$echarts.init(this.$refs['volumeOfTrade'])
      this.myChart.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      orderSummaryGetPlatTradeAmount(val, res => {
        this.tradeAmountObj = { ...res.data }
        this.rendCharts(this.tradeAmountObj)
      })
    },
    rendCharts(tradeAmountObj = {}) {
      const option = {
        // backgroundColor: "#05224d",
        title: {
          text: '单位：万元',
          x: '-6px',
          textStyle: {
            color: '#929CA7',
            fontSize: '14',
            fontWeight: '500'
          }
        },
        tooltip: {
          trigger: 'axis',
          // backgroundColor: 'rgba(0,0,0,0.55)',
          textBorderWidth: 0,
          formatter: function(a) {
            const html = `<p>${a[0].axisValue}交易数据</p><p>订单数：${a[0].data}笔</p><p>交易数量：${a[1].data}吨</p><p>交易额：${a[2].data}万元</p>`
            return html
          },
          textStyle: {
            // color:'#fff',
            // fontSize:12,
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(2,126,255,0.08)',
              type: 'solid',
              width: 24
            }
          }
        },
        grid: {
          top: '11%',
          left: '0',
          right: '0',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLine: {
              // 坐标轴轴线相关设置。数学上的x轴
              show: false
            },
            axisTick: {
              show: false
            },
            data: tradeAmountObj.dateList
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 140,
            splitNumber: 5,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#e6e6e6'
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              margin: 20,
              color: '#4a4a4a'
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'line',
            smooth: false, // 是否平滑曲线显示
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 4,
            lineStyle: {
              color: '#1CAE39', // 线条颜色
              borderColor: '#1CAE39'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(0,0,0,0)'
            },
            itemStyle: {
              color: '#1CAE39'
            },
            data: tradeAmountObj.orderCount
          },
          {
            type: 'line',
            smooth: false, // 是否平滑曲线显示
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 4,
            lineStyle: {
              color: 'rgb(252,195,73)', // 线条颜色
              borderColor: 'rgb(252,195,73)'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(0,0,0,0)'
            },
            itemStyle: {
              color: 'rgb(252,195,73)'
            },
            data: tradeAmountObj.tradeWeight
          },
          {
            type: 'bar',
            // barWidth: 24,
            label: {
              show: true,
              position: 'top',
              color: 'rgba(0,0,0,0)'
            },
            itemStyle: {
              color: '#027EFF'
            },
            data: tradeAmountObj.tradeAmount
          }
        ]
      }
      this.myChart.hideLoading()
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-content {
  width: 100%;
  height: 400px;
  .volume-trade {
    width: 100%;
    height: 100%;
  }
}
</style>

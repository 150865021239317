<template>
  <!-- 价格走势 -->
  <div class="charts-content">
    <div ref="transactionCategoryLeft" class="transaction-category" />
    <div ref="transactionCategoryRight" class="transaction-category" />
  </div>
</template>

<script>
import { orderSummaryGetPlatTradeCategoryDistribution } from '@/api/monitor'
export default {
  props: {
    params: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      listArr: [],
      myChart: null,
      myChartRight: null
    }
  },
  watch: {
    params: {
      handler(newval) {
        newval.dimension = newval.dimension1
        newval.categoryName = newval.categoryName2 || ''
        if ([0, 2].includes(newval.type)) this.getList(newval)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getList(val) {
      this.myChart = this.$echarts.init(this.$refs['transactionCategoryLeft'])
      this.myChartRight = this.$echarts.init(this.$refs['transactionCategoryRight'])
      this.myChart.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      this.myChartRight.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      orderSummaryGetPlatTradeCategoryDistribution(val, res => {
        if (res.data.length > 0) {
          this.listArr = [...res.data]
          this.listArr.forEach(item => {
            item.name = item.oneGoodsName
            item.value = this.params.dimension === '00' ? item.weight : item.amount
          })
        }
        this.leftRendCharts(this.listArr)
        this.rightRendCharts(this.listArr[0].child)
      })
    },
    // 左边的饼图
    leftRendCharts(data) {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: ['#1F8EFF', '#23C343', '#F7765B', 'rgb(211,30,14)', '#FCC248', '#1F8EFF'],
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: data
          }
        ]
      }
      this.myChart.on('click', param => {
        // 添加点击事件
        const obj = this.listArr.find(item => item.oneGoodsName === param.data.oneGoodsName)
        if (obj.child && obj.child.length > 0) this.rightRendCharts(obj.child)
      })
      this.myChart.hideLoading()
      this.myChart.setOption(option)
    },
    // 右边的饼图
    rightRendCharts(data = []) {
      data.forEach(item => {
        item.name = item.oneGoodsName
        item.value = this.params.dimension === '00' ? item.weight : item.amount
      })
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: ['#1F8EFF', '#23C343', '#F7765B', 'rgb(211,30,14)', '#FCC248', '#1F8EFF'],
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            itemStyle: { color: '' },
            data: data
          }
        ]
      }
      this.myChartRight.hideLoading()
      this.myChartRight.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-content {
  display: flex;
  width: 100%;
  height: 400px;
  .transaction-category {
    width: 100%;
    height: 100%;
  }
}
</style>
